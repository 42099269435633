import React from "react";

declare global {
    interface Window {
        env: any;
    }
}

type EnvType = {
    REACT_APP_BACKEND_BASE_URL: string;
    REACT_APP_MAX_FILE_SIZE_KB: number;
    REACT_APP_VALID_UPLOAD_IMAGE_TYPES: React.InputHTMLAttributes<any>["accept"];
    REACT_APP_VALID_UPLOAD_CERTIFICATE_TYPES: React.InputHTMLAttributes<any>["accept"];
    REACT_APP_TIMEOUT_PERIOD_SECONDS: number;
    REACT_APP_TIMEOUT_WARNING_PERIOD_SECONDS: number;
    REACT_APP_RESTRICTED_LANGUAGES: string;
    REACT_APP_GOOGLE_API_KEY: string;
    REACT_APP_BETA_FEATURES_ENABLED: string;
    REACT_APP_ENVIRONMENT_NAME: string;
    REACT_APP_MIN_YEAR_PICKLIST_HARVEST_YEAR: number;
};
export const env: EnvType = { ...process.env, ...window.env };
