import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { ScopeCertificateDetails } from "../scopeCertificateTypes";
import { BaseSchema } from "yup";
import { scopeCertificateStatusType } from "../../auth/roleTypes";

export const scopeCertificateValidationSchema = buildSchema<ScopeCertificateDetails>({
    supplyChainOperator: validateString().isRequired(),
    standardVersion: validateString().isRequired(),
    certificationBody: validateString().isRequired(),
    cbScReferenceNumber: validateString().hasMaxLength(60),
    status: validateString().isRequired() as BaseSchema<scopeCertificateStatusType>,
    validFrom: validateDate().when("revisionNumber", {
        is: (n: number) => n !== null && n !== 0,
        then: (schema) => schema.isRequired(),
    }),
    validUntil: validateDate()
        .isAfterInclusive("validFrom", "scopeCertificate.detail.validFrom_input")
        .when("revisionNumber", {
            is: (n: number) => n !== null && n !== 0,
            then: (schema) => schema.isRequired(),
        }),
    approvingPerson: validateString(),
    additionalDeclaration: validateString().hasMaxLength(1000),
});
