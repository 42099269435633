import * as yup from "yup";
import { BaseSchema, DateSchema, NumberSchema, StringSchema } from "yup";
import { AnyObject, Maybe } from "yup/es/types";
import { maxLength, maxValue, minLength, minValue, required } from "./validationMessageProvider";
import { Assign, ObjectShape } from "yup/lib/object";
import { getLanguageKeys, LanguageKey } from "../i18n/languageTypes";
import Reference from "yup/es/Reference";
import { I18nKey } from "../i18n/useGtbTranslation";

/*
    This value is set to the max integer value the backend can surely handle. The frontend limits any number to this
    value.

    This greatly improves the handling of numbers that are too big to be handled by the backend.
    However, if under any circumstances the frontend is required to send bigger numbers to the backend, another solution
    which is more flexible than this has to be found.
 */
const BACKEND_COMPATIBLE_MAX_VALUE = 2147483647;

declare module "yup" {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType
    > extends yup.BaseSchema<TType, TContext, TOut> {
        isRequired(customMessage?: I18nKey): StringSchema<TType, TContext>;

        hasMaxLength(maxLength: number): StringSchema<TType, TContext>;

        hasMinLength(minLength: number): StringSchema<TType, TContext>;

        matchesPasswordPolicy(): StringSchema<TType, TContext>;

        isEmail(): StringSchema<TType, TContext>;

        isUrl(): StringSchema<TType, TContext>;
    }

    interface NumberSchema<
        TType extends Maybe<number> = number | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType
    > extends yup.BaseSchema<TType, TContext, TOut> {
        isRequired(): NumberSchema<TType, TContext>;

        hasMinValue(minValue: number): NumberSchema<TType, TContext>;

        hasMaxValue(maxValue: number): NumberSchema<TType, TContext>;

        isGreaterEqual(ref: Reference<number>, field: I18nKey, referencedField: I18nKey): NumberSchema<TType, TContext>;

        isLessEqual(ref: Reference<number>, field: I18nKey, referencedField: I18nKey): NumberSchema<TType, TContext>;
    }

    interface DateSchema<
        TType extends Maybe<Date> = Date | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType
    > extends yup.BaseSchema<TType, TContext, TOut> {
        isRequired(customMessage?: I18nKey): DateSchema<TType, TContext>;

        hasMinValue(minValue: Date, message?: I18nKey): DateSchema<TType, TContext>;

        hasMaxValue(maxValue: Date, message?: I18nKey): DateSchema<TType, TContext>;

        isAfterInclusive(ref: string, refKey: I18nKey): DateSchema<TType, TContext>;
    }
}

export function validateArray() {
    return yup.array().nullable();
}

export function validateArrayIsNotEmpty() {
    return yup
        .array()
        .required()
        .test("has-at-least-one-element", required, (value) => (value ? value.length > 0 : true))
        .typeError(required);
}

export function validateString() {
    return yup.string().nullable().trim();
}

export function requiredObject<ObjectType>() {
    return yup
        .object()
        .shape({} as ValidationBaseSchema<ObjectType>)
        .nullable()
        .required(required);
}

export function validateTranslation(maximumLength?: number) {
    return yup.object().shape(
        getLanguageKeys().reduce((acc, currentKey) => {
            if (maximumLength) acc[currentKey] = validateString().isRequired().hasMaxLength(maximumLength);
            else acc[currentKey] = validateString().isRequired();
            return acc;
        }, {} as { [key in LanguageKey]: StringSchema<any> })
    );
}

export function validateDate() {
    return yup.date().nullable().typeError("validation.noValidDate_message");
}

export function validateNumber() {
    return yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .hasMaxValue(BACKEND_COMPATIBLE_MAX_VALUE)
        .typeError("validation.noValidNumber_message");
}

function isRequired(this: BaseSchema, customMessage: I18nKey = required) {
    return this.required(customMessage);
}

function hasMaxLength(this: StringSchema, value: number) {
    return this.max(...maxLength(value));
}

function hasMinLength(this: StringSchema, value: number) {
    return this.min(...minLength(value));
}

function hasMinValue(this: NumberSchema, value: number) {
    return this.min(...minValue(value));
}

function hasMaxValue(this: NumberSchema, value: number) {
    return this.max(...maxValue(value));
}

function hasMinDate(
    this: DateSchema,
    value: Date,
    message: I18nKey = {
        key: "validation.minDate_message",
        options: { value, interpolation: { escapeValue: false } },
    }
) {
    return this.min(value, message);
}

function hasMaxDate(
    this: DateSchema,
    value: Date,
    message: I18nKey = {
        key: "validation.maxDate_message",
        options: { value, interpolation: { escapeValue: false } },
    }
) {
    return this.max(value, message);
}

function isAfterInclusive(this: DateSchema, ref: string, refKey: I18nKey) {
    return this.when(ref, {
        is: (d: Date) => d !== null,
        then: (schema) =>
            schema.min(yup.ref(ref), { key: "validation.dateNotAfter_message", options: { relatedDate: refKey } }),
    });
}

function isGreaterEqual(this: NumberSchema, ref: Reference<number>, field: I18nKey, referencedField: I18nKey) {
    return this.min(ref, {
        key: "validation.fieldGreaterEqualReferencedField_message",
        options: { field, referencedField },
    });
}

function isLessEqual(this: NumberSchema, ref: Reference<number>, field: I18nKey, referencedField: I18nKey) {
    return this.max(ref, {
        key: "validation.fieldLessEqualReferencedField_message",
        options: { field, referencedField },
    });
}

function matchesPasswordPolicy(this: StringSchema) {
    return this.matches(/[a-z]+/, "error.password.password_missing_lowercase")
        .matches(/[A-Z]+/, "error.password.password_missing_uppercase")
        .matches(/\d+/, "error.password.password_missing_number")
        .matches(/[^a-zA-Z\d]+/, "error.password.password_missing_special")
        .matches(/^\S*$/, "error.password.password_contains_whitespace");
}

function isEmail(this: StringSchema) {
    return this.email("validation.noValidEmail_message");
}

function isUrl(this: StringSchema) {
    return this.url("validation.noValidUrl_message");
}

yup.addMethod<StringSchema>(yup.string, "isRequired", isRequired);
yup.addMethod<StringSchema>(yup.string, "hasMaxLength", hasMaxLength);
yup.addMethod<StringSchema>(yup.string, "hasMinLength", hasMinLength);
yup.addMethod<StringSchema>(yup.string, "matchesPasswordPolicy", matchesPasswordPolicy);
yup.addMethod<StringSchema>(yup.string, "isEmail", isEmail);
yup.addMethod<StringSchema>(yup.string, "isUrl", isUrl);
yup.addMethod<NumberSchema>(yup.number, "isRequired", isRequired);
yup.addMethod<NumberSchema>(yup.number, "hasMinValue", hasMinValue);
yup.addMethod<NumberSchema>(yup.number, "hasMaxValue", hasMaxValue);
yup.addMethod<NumberSchema>(yup.number, "isGreaterEqual", isGreaterEqual);
yup.addMethod<NumberSchema>(yup.number, "isLessEqual", isLessEqual);
yup.addMethod<DateSchema>(yup.date, "isRequired", isRequired);
yup.addMethod<DateSchema>(yup.date, "hasMinValue", hasMinDate);
yup.addMethod<DateSchema>(yup.date, "hasMaxValue", hasMaxDate);
yup.addMethod<DateSchema>(yup.date, "isAfterInclusive", isAfterInclusive);

export default yup;

export type ValidationBaseSchema<T> = { [Key in keyof Partial<T>]: yup.BaseSchema<T[Key] | null | undefined> };

export type ValidationSchema<T> = yup.ObjectSchema<Assign<ObjectShape, ValidationBaseSchema<T>>>;

export const buildSchema = <ItemType>(
    schema: ValidationBaseSchema<ItemType>,
    excluded?: [string, string][]
): ValidationSchema<ItemType> => yup.object().shape(schema, excluded);
