import React, { useMemo } from "react";
import { useHelpContext } from "../../../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../../../components/entity/EntityDetailView";
import Tabs, { TabKey, TabProps } from "../../../../components/tabs/Tabs";
import { PreGtbProductDetails } from "../preGtbProductTypes";
import { TypedDetailViewProps } from "../../../../components/detailView/DetailView";
import PreGtbProductBasicData, { PreGtbProductBasicDataProps } from "./PreGtbProductBasicData";
import { PreGtbTransactionCertificateDetails } from "../../../preGtbTransactionCertificateTypes";
import PreGtbTcProductMaterialComposition from "../../materials/PreGtbTcProductMaterialComposition";

export default function PreGtbProductDetailView({
    detailViewProps,
    form,
    preGtbTcId,
    preGtbTcStatus,
    readOnly,
    labelGradesVisible,
    subListVisible,
    ...rest
}: PreGtbProductDetailViewProps) {
    useHelpContext("pre-gtb-product-details");

    const sublistTabs = useMemo<TabProps<PreGtbProductTabs>[]>(
        () => [
            {
                heading: "preGtbProduct.detail.preGtbTcMaterials_tab",
                element: (
                    <PreGtbTcProductMaterialComposition
                        form={form}
                        labelGradesVisible
                        preGtbTcId={preGtbTcId}
                        preGtbTcStatus={preGtbTcStatus}
                    />
                ),
            },
        ],
        [form, preGtbTcId, preGtbTcStatus]
    );

    return (
        <EntityDetailView {...rest} detailViewProps={detailViewProps} form={form}>
            <Tabs<PreGtbProductDetails, PreGtbProductTabs>
                tabs={[
                    {
                        heading: "preGtbProduct.detail.basicData_tab",
                        element: (
                            <PreGtbProductBasicData
                                form={form}
                                preGtbTcId={preGtbTcId}
                                readOnly={readOnly}
                                labelGradesVisible={labelGradesVisible}
                            />
                        ),
                    },
                    ...(subListVisible ? sublistTabs : []),
                ]}
                errors={form.form.formState.errors}
                errorToTab={{
                    id: "internal",
                    productNumber: "preGtbProduct.detail.basicData_tab",
                    productDetail: "preGtbProduct.detail.basicData_tab",
                    productCategory: "preGtbProduct.detail.basicData_tab",
                    productDetailPrint: "preGtbProduct.detail.basicData_tab",
                    labelGrade: "preGtbProduct.detail.basicData_tab",
                    labelGradeLabelGrade: "internal",
                    productCategoryPrint: "preGtbProduct.detail.basicData_tab",
                    availableVolume: "preGtbProduct.detail.basicData_tab",
                    certifiedWeight: "preGtbProduct.detail.basicData_tab",
                    preGtbTcTcNumber: "preGtbProduct.detail.basicData_tab",
                    netShippingWeight: "preGtbProduct.detail.basicData_tab",
                    supplementaryWeight: "preGtbProduct.detail.basicData_tab",
                    initialAvailableQuantity: "preGtbProduct.detail.basicData_tab",
                    usdaNopCompliant: "preGtbProduct.detail.basicData_tab",
                    preGtbTcStatus: "internal",
                }}
            />
        </EntityDetailView>
    );
}

type PreGtbProductTabs = TabKey<"preGtbProduct.detail.basicData_tab" | "preGtbProduct.detail.preGtbTcMaterials_tab">;

export interface PreGtbProductDetailViewProps extends TypedDetailViewProps<PreGtbProductDetails> {
    preGtbTcId: string;
    preGtbTcStatus?: PreGtbTransactionCertificateDetails["status"];
    labelGradesVisible: boolean;
    readOnly: PreGtbProductBasicDataProps["readOnly"];
    subListVisible: boolean;
}
