import useGtbTranslation from "../../i18n/useGtbTranslation";
import ControlledPickList, { ControlledPickListProps } from "../../components/Input/pickList/ControlledPickList";
import { useMemo } from "react";
import { FieldValues } from "react-hook-form/dist/types";
import { env } from "../../env";

export default function HarvestYearPickList<FormItemType extends FieldValues>({
    ...rest
}: HarvestYearPickListProps<FormItemType>) {
    const data: SelectableHarvestYear[] = usePreparedHarvestYears();
    const translation = useGtbTranslation();

    return (
        <ControlledPickList
            itemId={"id"}
            itemLabel={(selection) => {
                const startYear = selection.id;

                return translation({
                    key: "organicMaterialProduction.detail.harvestYear_input_entry_format",
                    options: { startYear, endYear: startYear + 1 },
                });
            }}
            loadAdditionalItem={(item) =>
                Promise.resolve({
                    id: item,
                })
            }
            data={data}
            {...rest}
        />
    );
}

function usePreparedHarvestYears() {
    return useMemo<SelectableHarvestYear[]>(() => {
        const thisYear = new Date().getUTCFullYear();
        const startYear = env.REACT_APP_MIN_YEAR_PICKLIST_HARVEST_YEAR;
        let years: { id: number }[] = [];
        for (let year = startYear; year <= thisYear; year++) {
            years.push({ id: Number(year) });
        }
        return years;
    }, []);
}

export interface HarvestYearPickListProps<FormItemType extends FieldValues>
    extends Omit<
        ControlledPickListProps<FormItemType, SelectableHarvestYear>,
        "itemId" | "itemLabel" | "loadAdditionalItem" | "data"
    > {}

export interface SelectableHarvestYear {
    id: number;
}
