import "./pregtbtcproductMaterialComposition.css";
import usePreGtbTcMaterialSubList from "./usePreGtbTcMaterialSubList";
import { PreGtbProductBasicDataProps } from "../products/details/PreGtbProductBasicData";
import { joinClassNames } from "../../../utils/StringUtils";
import GtbTextField from "../../../components/Input/GtbTextField";
import InfinityScrollSubList from "../../../components/grid/InfinityScrollSubList";
import { PreGtbTransactionCertificateDetails } from "../../preGtbTransactionCertificateTypes";

interface PreGtbTcProductMaterialCompositionProps extends Omit<PreGtbProductBasicDataProps, "readOnly"> {
    preGtbTcStatus?: PreGtbTransactionCertificateDetails["status"];
}

export default function PreGtbTcProductMaterialComposition({
    form,
    preGtbTcStatus,
}: PreGtbTcProductMaterialCompositionProps) {
    const preGtbtcMaterialSubList = usePreGtbTcMaterialSubList(preGtbTcStatus);

    return (
        <div className={joinClassNames("preGtbTcProductMaterialComposition", "formGrid")}>
            <GtbTextField
                label="tcProduct.detail.productCategoryPrint_input"
                className="productCategoryPrint"
                readOnly
                {...form.registerWithErrors("productCategoryPrint")}
            />
            <GtbTextField
                label="tcProduct.detail.productDetailPrint_input"
                className="productDetailPrint"
                readOnly
                {...form.registerWithErrors("productDetailPrint")}
            />
            <GtbTextField
                label="tcProduct.detail.labelGrade_input"
                className="labelGrade"
                readOnly
                {...form.registerWithErrors("labelGradeLabelGrade")}
            />
            <InfinityScrollSubList {...preGtbtcMaterialSubList} className="preGtbTcMaterials" />
        </div>
    );
}
