import { buildSchema, validateDate, validateString } from "../../utils/yupExtension";
import { CertificationRelationDetails } from "../certificationRelationTypes";

export const certificationRelationValidationSchema = buildSchema<CertificationRelationDetails>({
    certificationBody: validateString().isRequired(),
    supplyChainOperator: validateString().isRequired(),
    standard: validateString().isRequired(),
    cbLicenceNumber: validateString().hasMaxLength(40),
    startDate: validateDate().isRequired(),
    recertificationEmail: validateString().hasMaxLength(100).isEmail(),
});
