import {
    buildSchema,
    validateArray,
    validateDate,
    validateNumber,
    validateString,
} from "../../../../utils/yupExtension";
import { TcProductDetails } from "../tcProductTypes";

export const tcProductValidationSchema = buildSchema<TcProductDetails>({
    tcProductNumber: validateString(),
    relatedScProduct: validateString().isRequired(),
    sellerLegalName: validateString(),
    sellerFacilityBusinessName: validateString(),
    buyerLegalName: validateString(),
    buyerFacilityBusinessName: validateString(),
    numberOfUnits: validateNumber().hasMinValue(1).isRequired(),
    unitName: validateString().isRequired().hasMaxLength(20),
    netShippingWeight: validateNumber().isRequired(),
    supplementaryWeight: validateNumber().isRequired(),
    certifiedWeight: validateNumber(),
    nonCertifiedWeight: validateNumber(),
    availableQuantity: validateNumber(),
    byProductWeight: validateNumber(),
    availableByProductQuantity: validateNumber(),
    orderNumber: validateString().isRequired().hasMaxLength(60),
    articleNumber: validateString().hasMaxLength(60),
    productionDate: validateDate().isRequired(),
    tcProcessors: validateArray(),
    lastProcessor: validateString(),
    additionalInformation: validateString().hasMaxLength(200),
});
